<template>
    <div>
        <div id="checkout">
        <!-- Checkout will insert the payment form here -->
        </div>
    </div>
</template>

<script>
import { getApplicationInfo, getStripeUrl } from "../../api/index";
import VueQr from "vue-qr";
export default {
    name: "Payment",
    data() {
        return {
            application_id: this.$route.query.id,
            amount:this.$route.query.amount
        };
    },
    components: {
        VueQr,
    },
    mounted() {
       
        console.log(11111421421)

        this.fetchData();
    },
    methods: {

    
        fetchData() {
           

            console.log(11111421421)

            let line_items = [ {
                            price_data: {
                                currency: "usd",
                                product_data: {
                                    name: '2024 World Student Publication Review',
                                },
                                unit_amount: this.amount * 100,
                            },
                            quantity: 1,
                        }];
                    
                    this.pay(this.application_id, line_items);
        },
      
        goStripe() {
            // if (!this.isAgree) {
            //     this.$message.warning("Please read terms and agree it");
            //     return;
            // }
            // getStripeUrl(this.applicationInfo._id.$id).then(res => {
            //     // if (res.data.code === 1) {
            //     //     this.$message.success('Pay success~')
            //     //     this.goBack()
            //     //     return
            //     // }
            //     // if (res.data.code === 0) {
            //     //     window.open(res.data.data, '_blank')
            //     // } else {
            //     //     this.$message.error(res.data.msg)
            //     // }
            // })

            

        },
       

        getOrderPay(){
            getOrder({
                id: this.applicationInfo._id.$id,
            }).then((res) => {
                if (res.data.code === 0) {
                    
                }
            })
        },

        pay(order_id, line_items) {

            console.log(11111421421)
            // Initialize Stripe.js
            const stripe = Stripe(
                "pk_live_51P128qP6BucL4HOz2SJY6gWLATlcK5rGdNTnePD780jhsy6ZLf7ZxIHxRE1oBLk9pchjvIoqHUwnPPGB10DmtGOO00yikhqAr0"
            );

            initialize();
            // Fetch Checkout Session and retrieve the client secret
            async function initialize() {
                const fetchClientSecret = async () => {
                const response = await fetch(
                    "https://web-backend.reachable-edu.com/Stripe/swprcheckout.php",
                    {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        application_id: order_id,
                        line_items: line_items,
                    }),
                    }
                );
                let result = await response.json();
                const { clientSecret } = result;
                return clientSecret;
                };

                // Initialize Checkout
                const checkout = await stripe.initEmbeddedCheckout({
                fetchClientSecret,
                });

                // Mount Checkout
                checkout.mount("#checkout");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.pay-info {
    background: #fff5f4;
    color: #7F7F7F
}

.btn {
    width: 160px;
}

.pay-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QRcode {
    width: 220px;
    height: 200px;
    background: url("../../assets/image/qrcode.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-top: 20px;

    .text {
        width: 100%;
        word-break: break-word;
        text-align: center;
    }
}
</style>